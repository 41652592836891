import revive_payload_client_4sVQNw7RlN from "/builds/enkhod/kudos-web-v2/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import router_jmwsqit4Rs from "/builds/enkhod/kudos-web-v2/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/builds/enkhod/kudos-web-v2/node_modules/nuxt/dist/app/plugins/payload.client.js";
import plugin_vue3_A0OWXRrUgq from "/builds/enkhod/kudos-web-v2/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/builds/enkhod/kudos-web-v2/.nuxt/components.plugin.mjs";
import unhead_KgADcZ0jPj from "/builds/enkhod/kudos-web-v2/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import prefetch_client_5tzzN0oIVL from "/builds/enkhod/kudos-web-v2/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import chunk_reload_client_UciE0i6zes from "/builds/enkhod/kudos-web-v2/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import directive_click_outside_client_qvVMbo9yqO from "/builds/enkhod/kudos-web-v2/plugins/directive-click-outside.client.ts";
import date_aCcDt3CXqN from "/builds/enkhod/kudos-web-v2/plugins/date.js";
import vuetify_7h9QAQEssH from "/builds/enkhod/kudos-web-v2/plugins/vuetify.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  unhead_KgADcZ0jPj,
  prefetch_client_5tzzN0oIVL,
  chunk_reload_client_UciE0i6zes,
  directive_click_outside_client_qvVMbo9yqO,
  date_aCcDt3CXqN,
  vuetify_7h9QAQEssH
]