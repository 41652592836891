// plugins/vuetify.js
import { createVuetify } from "vuetify";

export default defineNuxtPlugin((nuxtApp) => {
  const vuetify = createVuetify({
    theme: {
      themes: {
        light: {
          dark: false,
          colors: {
            primary: "#6200ee",
          },
        },
      },
    },
  });

  nuxtApp.vueApp.use(vuetify);
});
