import { default as indexFnpADQjpQJMeta } from "/builds/enkhod/kudos-web-v2/pages/index.vue?macro=true";
import { default as layoutTest5pxjOSDSiqMeta } from "/builds/enkhod/kudos-web-v2/pages/layoutTest.vue?macro=true";
import { default as menuDtlfZDVQ1gMeta } from "/builds/enkhod/kudos-web-v2/pages/menu.vue?macro=true";
import { default as analyzecrCnNdyMf8Meta } from "/builds/enkhod/kudos-web-v2/pages/project/[id]/analyze.vue?macro=true";
import { default as deletedresultsypz5eTXT31Meta } from "/builds/enkhod/kudos-web-v2/pages/project/[id]/deletedresults.vue?macro=true";
import { default as _91result_45id_93734ejAiZTkMeta } from "/builds/enkhod/kudos-web-v2/pages/project/[id]/results/[result-id].vue?macro=true";
import { default as indexa9MyrusR0CMeta } from "/builds/enkhod/kudos-web-v2/pages/project/[id]/results/index.vue?macro=true";
import { default as savedresults1heU7pV8VZMeta } from "/builds/enkhod/kudos-web-v2/pages/project/[id]/savedresults.vue?macro=true";
import { default as settings1Z9XbiXMGdMeta } from "/builds/enkhod/kudos-web-v2/pages/project/[id]/settings.vue?macro=true";
import { default as source8lctGkKsRFMeta } from "/builds/enkhod/kudos-web-v2/pages/project/[id]/source.vue?macro=true";
import { default as indexa5YzaGJ4mcMeta } from "/builds/enkhod/kudos-web-v2/pages/project/index.vue?macro=true";
import { default as selectTestw9RqE1bnRcMeta } from "/builds/enkhod/kudos-web-v2/pages/selectTest.vue?macro=true";
export default [
  {
    name: indexFnpADQjpQJMeta?.name ?? "index",
    path: indexFnpADQjpQJMeta?.path ?? "/",
    meta: indexFnpADQjpQJMeta || {},
    alias: indexFnpADQjpQJMeta?.alias || [],
    redirect: indexFnpADQjpQJMeta?.redirect || undefined,
    component: () => import("/builds/enkhod/kudos-web-v2/pages/index.vue").then(m => m.default || m)
  },
  {
    name: layoutTest5pxjOSDSiqMeta?.name ?? "layoutTest",
    path: layoutTest5pxjOSDSiqMeta?.path ?? "/layoutTest",
    meta: layoutTest5pxjOSDSiqMeta || {},
    alias: layoutTest5pxjOSDSiqMeta?.alias || [],
    redirect: layoutTest5pxjOSDSiqMeta?.redirect || undefined,
    component: () => import("/builds/enkhod/kudos-web-v2/pages/layoutTest.vue").then(m => m.default || m)
  },
  {
    name: menuDtlfZDVQ1gMeta?.name ?? "menu",
    path: menuDtlfZDVQ1gMeta?.path ?? "/menu",
    meta: menuDtlfZDVQ1gMeta || {},
    alias: menuDtlfZDVQ1gMeta?.alias || [],
    redirect: menuDtlfZDVQ1gMeta?.redirect || undefined,
    component: () => import("/builds/enkhod/kudos-web-v2/pages/menu.vue").then(m => m.default || m)
  },
  {
    name: analyzecrCnNdyMf8Meta?.name ?? "project-id-analyze",
    path: analyzecrCnNdyMf8Meta?.path ?? "/project/:id()/analyze",
    meta: analyzecrCnNdyMf8Meta || {},
    alias: analyzecrCnNdyMf8Meta?.alias || [],
    redirect: analyzecrCnNdyMf8Meta?.redirect || undefined,
    component: () => import("/builds/enkhod/kudos-web-v2/pages/project/[id]/analyze.vue").then(m => m.default || m)
  },
  {
    name: deletedresultsypz5eTXT31Meta?.name ?? "project-id-deletedresults",
    path: deletedresultsypz5eTXT31Meta?.path ?? "/project/:id()/deletedresults",
    meta: deletedresultsypz5eTXT31Meta || {},
    alias: deletedresultsypz5eTXT31Meta?.alias || [],
    redirect: deletedresultsypz5eTXT31Meta?.redirect || undefined,
    component: () => import("/builds/enkhod/kudos-web-v2/pages/project/[id]/deletedresults.vue").then(m => m.default || m)
  },
  {
    name: _91result_45id_93734ejAiZTkMeta?.name ?? "project-id-results-resultid",
    path: _91result_45id_93734ejAiZTkMeta?.path ?? "/project/:id()/results/:resultid()",
    meta: _91result_45id_93734ejAiZTkMeta || {},
    alias: _91result_45id_93734ejAiZTkMeta?.alias || [],
    redirect: _91result_45id_93734ejAiZTkMeta?.redirect || undefined,
    component: () => import("/builds/enkhod/kudos-web-v2/pages/project/[id]/results/[result-id].vue").then(m => m.default || m)
  },
  {
    name: indexa9MyrusR0CMeta?.name ?? "project-id-results",
    path: indexa9MyrusR0CMeta?.path ?? "/project/:id()/results",
    meta: indexa9MyrusR0CMeta || {},
    alias: indexa9MyrusR0CMeta?.alias || [],
    redirect: indexa9MyrusR0CMeta?.redirect || undefined,
    component: () => import("/builds/enkhod/kudos-web-v2/pages/project/[id]/results/index.vue").then(m => m.default || m)
  },
  {
    name: savedresults1heU7pV8VZMeta?.name ?? "project-id-savedresults",
    path: savedresults1heU7pV8VZMeta?.path ?? "/project/:id()/savedresults",
    meta: savedresults1heU7pV8VZMeta || {},
    alias: savedresults1heU7pV8VZMeta?.alias || [],
    redirect: savedresults1heU7pV8VZMeta?.redirect || undefined,
    component: () => import("/builds/enkhod/kudos-web-v2/pages/project/[id]/savedresults.vue").then(m => m.default || m)
  },
  {
    name: settings1Z9XbiXMGdMeta?.name ?? "project-id-settings",
    path: settings1Z9XbiXMGdMeta?.path ?? "/project/:id()/settings",
    meta: settings1Z9XbiXMGdMeta || {},
    alias: settings1Z9XbiXMGdMeta?.alias || [],
    redirect: settings1Z9XbiXMGdMeta?.redirect || undefined,
    component: () => import("/builds/enkhod/kudos-web-v2/pages/project/[id]/settings.vue").then(m => m.default || m)
  },
  {
    name: source8lctGkKsRFMeta?.name ?? "project-id-source",
    path: source8lctGkKsRFMeta?.path ?? "/project/:id()/source",
    meta: source8lctGkKsRFMeta || {},
    alias: source8lctGkKsRFMeta?.alias || [],
    redirect: source8lctGkKsRFMeta?.redirect || undefined,
    component: () => import("/builds/enkhod/kudos-web-v2/pages/project/[id]/source.vue").then(m => m.default || m)
  },
  {
    name: indexa5YzaGJ4mcMeta?.name ?? "project",
    path: indexa5YzaGJ4mcMeta?.path ?? "/project",
    meta: indexa5YzaGJ4mcMeta || {},
    alias: indexa5YzaGJ4mcMeta?.alias || [],
    redirect: indexa5YzaGJ4mcMeta?.redirect || undefined,
    component: () => import("/builds/enkhod/kudos-web-v2/pages/project/index.vue").then(m => m.default || m)
  },
  {
    name: selectTestw9RqE1bnRcMeta?.name ?? "selectTest",
    path: selectTestw9RqE1bnRcMeta?.path ?? "/selectTest",
    meta: selectTestw9RqE1bnRcMeta || {},
    alias: selectTestw9RqE1bnRcMeta?.alias || [],
    redirect: selectTestw9RqE1bnRcMeta?.redirect || undefined,
    component: () => import("/builds/enkhod/kudos-web-v2/pages/selectTest.vue").then(m => m.default || m)
  }
]