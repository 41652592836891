export const appHead = {"meta":[{"name":"viewport","content":"width=device-width, initial-scale=1"},{"charset":"utf-8"},{"hid":"description","name":"description","content":"Kudos web v2"},{"property":"og:site_name","content":"Kudos web"},{"hid":"og:type","property":"og:type","content":"website"},{"hid":"og:url","property":"og:url","content":"https://kudos.mn"},{"hid":"og:image:secure_url","property":"og:image:secure_url","content":"https://sources.kudos.mn/thumbnail.png"},{"hid":"og:title","property":"og:title","content":"Kudos web"},{"hid":"og:description","property":"og:description","content":"Kudos web v2"},{"hid":"og:image","property":"og:image","content":"https://sources.kudos.mn/thumbnail.png"},{"name":"twitter:card","content":"summary_large_image"},{"hid":"twitter:url","name":"twitter:url","content":"https://kudos.mn"},{"hid":"twitter:title","name":"twitter:title","content":"Kudos web"},{"hid":"twitter:description","name":"twitter:description","content":"Kudos web v2"},{"hid":"twitter:image","name":"twitter:image","content":"https://sources.kudos.mn/thumbnail.png"}],"link":[{"rel":"stylesheet","href":"/inter/inter.css"},{"rel":"icon","type":"image/x-icon","href":"/favicon.ico"},{"rel":"apple-touch-icon","sizes":"180x180","href":"/apple-touch-icon.png"},{"rel":"icon","type":"image/png","sizes":"32x32","href":"/favicon-32x32.png"},{"rel":"icon","type":"image/png","sizes":"16x16","href":"/favicon-16x16.png"},{"rel":"canonical","href":"https://kudos.mn"}],"style":[],"script":[],"noscript":[],"title":"Kudos web","titleTemplate":"%s"}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const renderJsonPayloads = true

export const componentIslands = false

export const devPagesDir = null

export const devRootDir = null