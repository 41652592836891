export default defineNuxtPlugin((nuxtApp) => {
  var coolDate = function (app, date) {
    var seconds = Math.floor((new Date() - date) / 1000);
    if (seconds < 86400 * 30) {
      var interval = Math.floor(seconds / 86400);
      if (interval >= 1) {
        if (interval == 1) {
          return app.i18n.i("yesterday")[0];
        }
        return interval + " " + app.i18n.i("days_ago");
      }
      interval = Math.floor(seconds / 3600);
      if (interval >= 1) {
        if (interval == 1) {
          return app.i18n.i("hour_ago")[0];
        }
        return interval + " " + app.i18n.i("hours_ago");
      }
      interval = Math.floor(seconds / 60);
      if (interval >= 1) {
        if (interval == 1) {
          return app.i18n.i("minute_ago")[0];
        }
        return interval + " " + app.i18n.i("minutes_ago");
      }
      return app.i18n.i("just_now")[0];
    } else {
      return fullDate(date);
    }
  };

  var onlyDate = function (date) {
    return (
      date.getFullYear() +
      "-" +
      ("0" + (date.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + date.getDate()).slice(-2)
    );
  };

  var fullDate = function (date) {
    return date.toLocaleString("mn-MN");
  };

  // 31 Aug 2023, 12:30PM format
  var kudosDate = function (date) {
    function formatAMPM(date) {
      let hours = date.getHours();
      let minutes = date.getMinutes();
      const ampm = hours >= 12 ? "PM" : "AM";
      hours = hours % 12;
      hours = hours ? hours : 12; // Handle midnight
      minutes = minutes < 10 ? `0${minutes}` : minutes;
      return `${hours}:${minutes}${ampm}`;
    }

    // Months as text
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const formattedDate = `${date.getDate()} ${
      months[date.getMonth()]
    } ${date.getFullYear()}, ${formatAMPM(date)}`;
    return formattedDate;
    // Function to format the time in AM/PM format
  };

  var yearMonth = function (app, date) {
    return app.i18n.t("_year_month", {
      year: date.getFullYear(),
      month: app.i18n.i(`_months.${date.getMonth() + 1}`),
    });
  };

  return {
    provide: {
      fullDate: (dateStr) => {
        var date = new Date(dateStr);
        return fullDate(date);
      },
      coolDate: (dateStr) => {
        var date = new Date(dateStr);
        return coolDate(date);
      },
      yearMonth: (dateStr) => {
        var date = new Date(dateStr);
        return yearMonth(date);
      },
      onlyDate: (dateStr) => {
        var date = new Date(dateStr);
        return onlyDate(date);
      },
      kudosDate: (dateStr) => {
        var date = new Date(dateStr);
        return kudosDate(date);
      },
    },
  };
});
